import styled from 'styled-components'

import { media } from '../../lib/cssHelpers'

interface Props {
  defaultPadding: boolean
  dark?: boolean
}

export default styled.div`
  padding: ${(p: Props) => p.defaultPadding && '30px'};
  box-sizing: border-box;
  flex-grow: 1;
  box-shadow: 0 0 1px 0 rgba(23, 52, 110, 0.08), 4px 8px 24px 0 rgba(31, 46, 77, 0.16);
  border-radius: 5px;
  margin-right: 40px;
  margin-left: 40px;
  background-color: ${(p: Props) => (p.dark ? 'black' : 'white')};
  min-height: 500px;
  transition: 0.3s;
  ${media.phone`
    margin-left: 25px;
    box-sizing: border-box;
  `};
`
